import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import { FilledButton } from '@jsluna/button';
import { Display2 } from '@jsluna/typography';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#writing-numbers"
            }}>{`Writing numbers`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#writing-dates"
            }}>{`Writing dates`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#writing-time"
            }}>{`Writing time`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "writing-numbers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-numbers",
        "aria-label": "writing numbers permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Writing numbers`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#use-numerals-instead-of-words">
        Use numerals instead of words
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#use-commas-for-numbers-over-999">
        Use commas for numbers over 999
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#spell-out-millions-and-billions">
        Spell out millions and billions
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#be-consistent-when-writing-about-money">
        Be consistent when writing about money
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#split-phone-numbers-into-3-segments">
        Split phone numbers into 3 segments
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="use-numerals-instead-of-words">Use numerals instead of words</h3>
    <p>{`Using numerals for numbers makes content easier to read, especially if the number is above 9.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>You have 5 new notifications</div>
    <div>We’ve reduced prices on more than 30 items</div>
  </Do>
  <Dont mdxType="Dont">
    <div>You have five new notifications</div>
    <div>We’ve reduced prices on more than thirty items</div>
  </Dont>
    </Guideline>
    <p>{`In certain circumstances, spelling the number out will make more sense than using numerals.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>It’s a one-off deal</div>
    <div>One or two of them</div>
  </Do>
  <Dont mdxType="Dont">
    <div>It’s a 1 off deal</div>
    <div>1 or 2 of them</div>
  </Dont>
    </Guideline>
    <p>{`If using ordinal numbers, like first and second, spell those out up to ninth. After that, use 10th, 11th and so on.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>Your first delivery will arrive tomorrow morning</div>
    <div>That's your 13th order with us</div>
  </Do>
  <Dont mdxType="Dont">
    <div>Your 1st delivery will arrive tomorrow morning</div>
    <div>That's your thirteenth order with us</div>
  </Dont>
    </Guideline>
    <h3 id="use-commas-for-numbers-over-999">Use commas for numbers over 999</h3>
    <p>{`Use a comma for numbers above 999.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>7,000</li>
      <li>23,756</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>7k</li>
      <li>23756</li>
    </ul>
  </Dont>
    </Guideline>
    <p>{`Plural numbers do not need apostrophes.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>1,000s</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>1,000's</li>
    </ul>
  </Dont>
    </Guideline>
    <h3 id="spell-out-millions-and-billions">Spell out millions and billions</h3>
    <p>{`If you're not writing about money, spell out millions and billions.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>3 billion people</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>3,000,000,000 people</li>
    </ul>
  </Dont>
    </Guideline>
    <p>{`If you're writing about money, abbreviate millions and billions.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>£3m in profit</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>£3 million in profit</li>
    </ul>
  </Dont>
    </Guideline>
    <h3 id="be-consistent-when-writing-about-money">
  Be consistent when writing about money
    </h3>
    <p>{`Don’t use a space between the currency symbol and the number.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">£35.99</Do>
  <Dont mdxType="Dont">£ 35.99</Dont>
    </Guideline>
    <p>{`When a user is checking out, include a decimal point and the pence even if the amount is a round number.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">£50.00</Do>
  <Dont mdxType="Dont">£50</Dont>
    </Guideline>
    <p>{`In normal sentences, don’t include a decimal point or the pence if the amount is a round number.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Get £10 off your first shop</Do>
  <Dont mdxType="Dont">Get £10.00 off your first shop</Dont>
    </Guideline>
    <p>{`Write out the entire number for all amounts under £1 million.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>£3,000</li>
      <li>£750,000</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>£3k</li>
      <li>£0.75million</li>
    </ul>
  </Dont>
    </Guideline>
    <h3 id="split-phone-numbers-into-3-segments">
  Split phone numbers into 3 segments
    </h3>
    <p>{`For geographic landline numbers, separate the area code from the local numbers with a space, and then separate the local numbers into two segments.`}</p>
    <p>{`Depending on the location, the area code could be 3, 4, or 5 digits. For example, 020 for London, 0161 for Manchester, or 01792 for Swansea.`}</p>
    <p>{`For mobile numbers, split them into three segments with 5 digits at the start and two segments of three at the end.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>020 1234 5678</li>
      <li>0161 123 4567</li>
      <li>01792 123 456</li>
      <li>07123 456 789</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>020-1234-5678</li>
      <li>01611234567</li>
      <li>(01792) 123456</li>
      <li>07123456789</li>
    </ul>
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "writing-dates",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-dates",
        "aria-label": "writing dates permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Writing dates`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#consider-the-circumstances">
        Consider the circumstances
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#use-the-long-date-format">
        Use the long date format
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#avoid-numerical-dates">
        Avoid numerical dates
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#use-the-word-'to'-for-date-ranges">
        Use the word 'to' for date ranges
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="consider-the-circumstances">Consider the circumstances</h3>
    <p>{`There's not one ideal date format for every situation. You should find the one that works best for the circumstance.`}</p>
    <p>{`We've included some guidance around the most preferred formats and when and how to use them.`}</p>
    <h3>{`Use the long date format`}</h3>
    <p>{`Where possible, write dates as 2 March or 27 October 2020. Don’t include ‘th’ or any commas.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>25 April</li>
      <li>6 June 2020</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>25th April</li>
      <li>6th June 2020</li>
    </ul>
  </Dont>
    </Guideline>
    <p>{`Only include the year if it’s relevant for the user. Include the day if that information is likely to be useful. If you do, the year can be left out of the date, especially if it's the current year.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Tuesday 27 October</Do>
  <Dont mdxType="Dont">Tuesday 27 October 2021</Dont>
    </Guideline>
    <h3 id="avoid-numerical-dates">Avoid numerical dates</h3>
    <p>{`Where possible, don't use numerical-only date formats as they can be confusing. If space is an issue, you can use a 3-letter abbreviation for the months. Don’t use a full stop after the month.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>6 Feb</li>
      <li>15 Oct</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>06/02/2020</li>
      <li>15 Oct.</li>
    </ul>
  </Dont>
    </Guideline>
    <p>{`If you need to use a numerical date, use the format DD/MM/YYYY.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">04/09/2020</Do>
  <Dont mdxType="Dont">04/09/20</Dont>
    </Guideline>
    <h3 id="use-the-word-'to'-for-date-ranges">
  Use the word 'to' for date ranges
    </h3>
    <p>{`For date ranges, use ‘to’ instead of a hyphen as it’s easier for screen reader users to understand. If your date range is in the same month, still include the month both times.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>10 April to 14 April</li>
      <li>Monday to Friday</li>
      <li>September 2019 to July 2020</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>10 - 14 April</li>
      <li>Monday - Friday</li>
      <li>September 2019 - July 2020</li>
    </ul>
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "writing-time",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#writing-time",
        "aria-label": "writing time permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Writing time`}</h2>
    <TableOfContents mdxType="TableOfContents">
  <ul>
    <li>
      <a className="ln-c-link" href="#use-the-12-hour-clock">
        Use the 12-hour clock
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#use-the-word-'to'-for-time-ranges">
        Use the word 'to' for time ranges
      </a>
    </li>
    <li>
      <a className="ln-c-link" href="#be-careful-writing-about-midnight">
        Be careful talking about midnight
      </a>
    </li>
  </ul>
    </TableOfContents>
    <h3 id="use-the-12-hour-clock">Use the 12-hour clock</h3>
    <p>{`Use the 12-hour clock where possible, followed by ‘am’ or ‘pm’ without any spaces.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>7.30pm</li>
      <li>5pm</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>19:30</li>
      <li>17:00</li>
    </ul>
  </Dont>
    </Guideline>
    <h3 id="use-the-word-'to'-for-time-ranges">
  Use the word 'to' for time ranges
    </h3>
    <p>{`For time ranges, use ‘to’ instead of a hyphen as it’s easier for screen readers to understand. If your time ranges are both in the morning or afternoon, still include am/pm after both times.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <ul className="ln-o-bare-list">
      <li>7.30pm to 8.30pm</li>
      <li>10am to 11am</li>
    </ul>
  </Do>
  <Dont mdxType="Dont">
    <ul className="ln-o-bare-list">
      <li>7:30 - 8:30pm</li>
      <li>10-11am</li>
    </ul>
  </Dont>
    </Guideline>
    <h3 id="be-careful-writing-about-midnight">
  Be careful writing about midnight
    </h3>
    <p>{`Use ‘11.59pm’ instead of ‘midnight’. Midnight is the first minute of the day, not the last.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Register for priority delivery by 11.59pm on Tuesday 14 June.</Do>
  <Dont mdxType="Dont">Register for priority delivery by midnight on Tuesday 14 June.</Dont>
    </Guideline>
    <p>{`Use ‘midday’ instead of ‘12pm’ or ‘noon’.`}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">We’ll deliver your order at midday.</Do>
  <Dont mdxType="Dont">We’ll deliver your order at 12 noon.</Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help you answer any questions around copy or help with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      